import React, { useState, useRef, useEffect } from "react";
import { Send, Key, Loader, X, AlertCircle } from "lucide-react";
import * as Dialog from "@radix-ui/react-dialog";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm";

// const API_URL = "http://localhost:8000/chat";
const API_URL = "/api/chat";


// Custom Alert Component
const Alert = ({ children, variant = "default" }) => {
  const bgColor = variant === "destructive" ? "bg-red-100" : "bg-blue-100";
  const textColor =
    variant === "destructive" ? "text-red-800" : "text-blue-800";

  return (
    <div className={`p-3 ${bgColor} ${textColor} rounded-md flex items-start`}>
      <AlertCircle className="h-5 w-5 mr-2 mt-0.5 flex-shrink-0" />
      <div>{children}</div>
    </div>
  );
};

export default function ForgeGPTChat() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [collection, setCollection] = useState("default");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [docContent, setDocContent] = useState("");
  const [docsMap, setDocsMap] = useState(new Map());
  const messagesEndRef = useRef(null);
  const [showSettings, setShowSettings] = useState(true);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    const link = document.createElement("link");
    link.href =
      "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap";
    link.rel = "stylesheet";
    document.head.appendChild(link);
    document.body.style.fontFamily = "'Inter', sans-serif";
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;
    if (!apiKey.trim()) {
      setError("Please enter your API key in the settings.");
      return;
    }

    setIsLoading(true);
    setError(null);

    const userMessage = { content: input, isBot: false };
    setMessages((prev) => [...prev, userMessage]);
    setInput("");

    try {
      const prevMessagesStr = messages.map(
        (msg) => `${msg.isBot ? "Assistant: " : "Human: "}${msg.content}`
      );
      prevMessagesStr.push(`Human: ${input}`);
      const response = await fetch(API_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          api_key: apiKey,
          collection: collection,
          message: input,
          prev_messages: prevMessagesStr,
        }),
      });

      if (!response.ok) throw new Error("Failed to get response");

      const data = await response.json();
      const botMessage = { content: data.bot_response, isBot: true };
      setMessages((prev) => [...prev, botMessage]);
      setDocsMap((prev) => new Map(prev).set(data.bot_response, data.docs));
    } catch (err) {
      setError(
        "An error occurred while fetching the response. Please try again."
      );
      console.error("Error:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDocClick = (docContent) => {
    setSelectedDoc(docContent);
    setDocContent(docContent);
  };

  const closePopup = () => {
    setSelectedDoc(null);
    setDocContent("");
  };

const HighlightedLink = ({ href, children }) => {
  const isBackendLink = href.startsWith("/") || href.startsWith("http");
  return isBackendLink ? (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="bg-yellow-200 font-semibold underline"
    >
      {children}
    </a>
  ) : (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="underline"
    >
      {children}
    </a>
  );
};

  return (
    <div className="flex flex-col h-screen bg-gray-100 font-inter">
      <header className="bg-gradient-to-r from-purple-600 to-indigo-600 shadow-lg">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-white">ForgeGPT Chat</h1>
          <button
            onClick={() => setShowSettings(!showSettings)}
            className="text-white hover:text-gray-200 transition-colors"
          >
            <Key className="h-5 w-5" />
          </button>
        </div>
      </header>

      <main className="flex-grow overflow-hidden relative p-2">
        <div className="bg-white shadow-xl rounded-lg h-full flex flex-col overflow-hidden max-w-5xl mx-auto">
          {showSettings && (
            <div className="p-3 border-b bg-gray-50">
              <div className="flex items-center space-x-2">
                <input
                  type="password"
                  placeholder="Enter your API Key"
                  value={apiKey}
                  onChange={(e) => setApiKey(e.target.value)}
                  className="flex-grow border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 transition duration-150 ease-in-out"
                />
                <select
                  value={collection}
                  onChange={(e) => setCollection(e.target.value)}
                  className="border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 transition duration-150 ease-in-out"
                >
                  <option value="default">Select Collection</option>
                  <option value="lombard-o1">lombard-o1</option>
                  <option value="immunefi">immunefi</option>
                  <option value="lombard">lombard</option>
                  {/* <option value="xterio-contracts">xterio-contracts</option> */}
                  <option value="immunefi-contracts">immunefi-contracts</option>
                </select>
              </div>
            </div>
          )}

          <div className="flex-grow overflow-y-auto p-3 space-y-3 custom-scrollbar">
            {error && <Alert variant="destructive">{error}</Alert>}
            {messages.map((message, index) => (
              <div
                key={index}
                className={`flex ${
                  message.isBot ? "justify-start" : "justify-end"
                }`}
              >
                <div
                  className={`rounded-lg px-3 py-2 max-w-[80%] shadow-md ${
                    message.isBot
                      ? "bg-blue-100 text-blue-900"
                      : "bg-green-100 text-green-900"
                  }`}
                >
                  {message.isBot ? (
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      components={{
                        code({ node, inline, className, children, ...props }) {
                          const match = /language-(\w+)/.exec(className || "");
                          return !inline && match ? (
                            <SyntaxHighlighter
                              style={atomDark}
                              language={match[1]}
                              PreTag="div"
                              {...props}
                              className="rounded-md text-sm"
                            >
                              {String(children).replace(/\n$/, "")}
                            </SyntaxHighlighter>
                          ) : (
                            <code
                              className={`${className} bg-gray-200 rounded-md px-1 py-0.5 text-sm`}
                              {...props}
                            >
                              {children}
                            </code>
                          );
                        },
                        a: HighlightedLink,
                      }}
                      className="prose max-w-none"
                    >
                      {message.content}
                    </ReactMarkdown>
                  ) : (
                    <p className="text-sm">{message.content}</p>
                  )}
                  {message.isBot &&
                    docsMap.get(message.content)?.length > 0 && (
                      <div className="mt-2">
                        <h5 className="text-sm font-medium text-gray-900 mb-1">
                          Supporting Docs
                        </h5>
                        <div className="flex flex-wrap gap-1">
                          {docsMap.get(message.content).map((doc, index) => (
                            <button
                              key={index}
                              className="cursor-pointer text-blue-600 hover:text-blue-800 underline text-sm flex items-center"
                              onClick={() => handleDocClick(doc.content)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 mr-1"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              {/* {`${index + 1}`} */}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
            <div className="h-20"></div>
          </div>
        </div>
      </main>

      <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 w-full max-w-3xl px-2">
        <form
          onSubmit={handleSubmit}
          className="flex space-x-2 bg-white rounded-lg shadow-lg p-2"
        >
          <input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your message here..."
            className="flex-grow border-none focus:ring-0 outline-none px-3 py-2"
          />
          <button
            type="submit"
            className="flex items-center px-3 py-2 text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-colors"
            disabled={isLoading}
          >
            {isLoading ? <Loader className="animate-spin" /> : <Send />}
          </button>
        </form>
      </div>

      <Dialog.Root open={!!selectedDoc} onOpenChange={closePopup}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black/30" />
          <Dialog.Content className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-4 shadow-lg max-w-3xl w-full max-h-[90vh] overflow-hidden">
            <div className="flex justify-between items-center mb-3">
              <Dialog.Title className="text-xl font-bold">
                Document
              </Dialog.Title>
              <Dialog.Close asChild>
                <button className="text-gray-400 hover:text-gray-600">
                  <X className="h-5 w-5" />
                </button>
              </Dialog.Close>
            </div>
            <div className="overflow-y-auto max-h-[calc(90vh-5rem)]">
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "");
                    return !inline && match ? (
                      <SyntaxHighlighter
                        style={atomDark}
                        language={match[1]}
                        PreTag="div"
                        {...props}
                        className="rounded-md text-sm"
                      >
                        {String(children).replace(/\n$/, "")}
                      </SyntaxHighlighter>
                    ) : (
                      <code
                        className={`${className} bg-gray-200 rounded-md px-1 py-0.5 text-sm`}
                        {...props}
                      >
                        {children}
                      </code>
                    );
                  },
                }}
                className="prose max-w-none"
              >
                {docContent}
              </ReactMarkdown>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
}
